var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sale-wrapper bj" },
    [
      _c("div", { staticClass: "title1" }, [
        _c(
          "div",
          { staticClass: "seach" },
          [
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择类型" },
                    model: {
                      value: _vm.from1.buy_way,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "buy_way", $$v)
                      },
                      expression: "from1.buy_way",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "地采", value: "1" } }),
                    _c("el-option", { attrs: { label: "基采", value: "2" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择标签",
                    },
                    model: {
                      value: _vm.from1.promotion_tag_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "promotion_tag_id", $$v)
                      },
                      expression: "from1.promotion_tag_id",
                    },
                  },
                  _vm._l(_vm.searchdata.promotion_tag_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入产地", clearable: "" },
                  model: {
                    value: _vm.from1.origin_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "origin_name", $$v)
                    },
                    expression: "from1.origin_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择等级",
                    },
                    model: {
                      value: _vm.from1.grade,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "grade", $$v)
                      },
                      expression: "from1.grade",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.id, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入商品名称", clearable: "" },
                  model: {
                    value: _vm.from1.product_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "product_name", $$v)
                    },
                    expression: "from1.product_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入货号", clearable: "" },
                  model: {
                    value: _vm.from1.sku_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "sku_code", $$v)
                    },
                    expression: "from1.sku_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入供应商名称", clearable: "" },
                  model: {
                    value: _vm.from1.supplier_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supplier_name", $$v)
                    },
                    expression: "from1.supplier_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入采购员名称", clearable: "" },
                  model: {
                    value: _vm.from1.buyer_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "buyer_name", $$v)
                    },
                    expression: "from1.buyer_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    clearable: "",
                    "start-placeholder": "视频最后一次更新时间起",
                    "end-placeholder": "视频最后一次更新时间止",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: { change: (val) => _vm.addTimeChange(val, "video_time") },
                  model: {
                    value: _vm.video_time,
                    callback: function ($$v) {
                      _vm.video_time = $$v
                    },
                    expression: "video_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    clearable: "",
                    "start-placeholder": "主图最后一次更新时间起",
                    "end-placeholder": "主图最后一次更新时间止",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: { change: (val) => _vm.addTimeChange(val, "kv_time") },
                  model: {
                    value: _vm.kv_time,
                    callback: function ($$v) {
                      _vm.kv_time = $$v
                    },
                    expression: "kv_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择集配中心",
                    },
                    model: {
                      value: _vm.from1.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistic_business_id", $$v)
                      },
                      expression: "from1.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.searchdata.business_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择一级品类",
                    },
                    model: {
                      value: _vm.from1.first_cid,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "first_cid", $$v)
                      },
                      expression: "from1.first_cid",
                    },
                  },
                  _vm._l(_vm.searchdata.first_category_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择二级品类",
                    },
                    model: {
                      value: _vm.from1.cid,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "cid", $$v)
                      },
                      expression: "from1.cid",
                    },
                  },
                  _vm._l(_vm.searchdata.second_category_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择三级品类",
                    },
                    model: {
                      value: _vm.from1.category_tag_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "category_tag_id", $$v)
                      },
                      expression: "from1.category_tag_id",
                    },
                  },
                  _vm._l(_vm.searchdata.category_tag_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    clearable: "",
                    "start-placeholder": "创建日期始",
                    "end-placeholder": "创建日期止",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: { change: (val) => _vm.timechange(val, "delivery_time") },
                  model: {
                    value: _vm.delivery_time,
                    callback: function ($$v) {
                      _vm.delivery_time = $$v
                    },
                    expression: "delivery_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inputs",
                staticStyle: { width: "250px !important" },
              },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "货号批量搜索,中间英文逗号隔开",
                    clearable: "",
                  },
                  model: {
                    value: _vm.from1.sku_list,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "sku_list", $$v)
                    },
                    expression: "from1.sku_list",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    clearable: "",
                    "start-placeholder": "最后上架时间起",
                    "end-placeholder": "最后上架时间止",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: {
                    change: (val) => _vm.addTimeChange(val, "online_time"),
                  },
                  model: {
                    value: _vm.online_time,
                    callback: function ($$v) {
                      _vm.online_time = $$v
                    },
                    expression: "online_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    clearable: "",
                    "start-placeholder": "最后下架时间起",
                    "end-placeholder": "最后下架时间止",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: {
                    change: (val) => _vm.addTimeChange(val, "offline_time"),
                  },
                  model: {
                    value: _vm.offline_time,
                    callback: function ($$v) {
                      _vm.offline_time = $$v
                    },
                    expression: "offline_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      icon: "el-icon-upload2",
                      disabled: _vm.disabledExport,
                    },
                    on: { click: _vm.onHandleExport },
                  },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "fullname",
                  align: "center",
                  label: "商品主图",
                  fixed: "left",
                  "min-width": "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("img", {
                            staticClass: "goods-image",
                            attrs: { src: scope.row.kv, alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandelDetails(scope.row)
                              },
                            },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(scope.row.kv_last_time_str || "")),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.srp_video_last_time_str || "")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fullname",
                  align: "center",
                  label: "商品名称",
                  fixed: "left",
                  "min-width": "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$empty.empty(
                                scope.row.title
                              )}(${_vm.$empty.empty(scope.row.sku_code)})`
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "buy_way_txt", align: "center", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.buy_way_txt)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "等级" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.grade)) +
                            "级 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "毛重" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.gross_weight)) +
                            "斤 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "毛单价" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(scope.row.gross_weight_price)
                            ) +
                            "元/斤 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "净重" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.weight)) +
                            "斤 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "净单价" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.weight_price)) +
                            "元/斤 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "开市价" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.price * 10000) / 100 / 10000
                              )
                            ) +
                            "元 "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "供货商" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.supplier
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.supplier.title)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "采购员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.buyer
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.buyer.fullname)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.create_time)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "库存数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.stock)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "first_cid_name",
                  align: "center",
                  label: "一级品类",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.first_cid_name)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "cid_name", align: "center", label: "二级品类" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.cid_name)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "category_tag",
                  align: "center",
                  label: "三级品类",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (scope.row.category_tag &&
                                scope.row.category_tag.name) ||
                                "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "origin_name", align: "center", label: "产地" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.origin_name)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "spec", align: "center", label: "规格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.spec)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "supply_time",
                  align: "center",
                  label: "供应商提交审核时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.supply_time)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "online_type_text",
                  align: "center",
                  label: "发布类型",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(scope.row.online_type_text)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "last_online_time",
                  align: "center",
                  label: "最后上架时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(scope.row.last_online_time)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "last_offline_time",
                  align: "center",
                  label: "最后下架时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(scope.row.last_offline_time)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "promotion_tag",
                  align: "center",
                  label: "标签",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.promotion_tag
                                ? scope.row.promotion_tag
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "intro_20", align: "center", label: "简介" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.intro_20)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "total_score", align: "center", label: "得分" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: "center",
                  "min-width": "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.storageHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看发售仓")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleQrCode(scope.row)
                              },
                            },
                          },
                          [_vm._v("小程序二维码")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editData(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看变更记录")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "enterprise personindex" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: "查看发售仓(" + _vm.listtotal + ")",
                width: "70%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.tableVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "margin-bottom": "20px" } },
                [
                  _c(
                    "div",
                    { staticClass: "inputs" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入城市仓名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.tdc_name,
                          callback: function ($$v) {
                            _vm.tdc_name = $$v
                          },
                          expression: "tdc_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.tdc_namesearch },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-box d-flex flex-column",
                  staticStyle: { height: "90%" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: {
                        data: _vm.listData,
                        height: "100%",
                        border: true,
                        "header-cell-style": {
                          color: "#333333",
                          background: "#EFF6FF",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "100px",
                          align: "center",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          align: "center",
                          label: "城市仓",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.name)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-position" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          type: "primary",
                          background: "",
                          "current-page": _vm.currentlistPage,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.salePageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.listtotal,
                        },
                        on: {
                          "size-change": _vm.handleListChange,
                          "current-change": _vm.handleListCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": 100,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("qr-code-dialog", { ref: "refQrCode" }),
      _c("goods-change-dialog", { ref: "refGoodsChangeDialog" }),
      _c("Salegoodseditdialog", {
        ref: "Salegoodseditdialog",
        on: { tablechange: _vm.tablechange },
      }),
      _vm.detailsVisible
        ? _c(
            "div",
            { staticClass: "dialogbj" },
            [
              _c("div", { staticStyle: { "text-align": "right" } }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function ($event) {
                      _vm.detailsVisible = false
                    },
                  },
                }),
              ]),
              _c("goods-details", {
                attrs: {
                  loading: _vm.detailsLoading,
                  details: _vm.goodsDetail,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }